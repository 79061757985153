import React, { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useCartContext } from '../../../../contexts/CartContext';
import { useCheckoutContext } from '../../../../contexts/CheckoutContext';
import SectionHeader from '../sectionHeader';
import useAddresses from '../../../../hooks/useAddresses';
import ShippingAddressBlock from '../../Address/shipping';
import ShippingSelect, { getSelectedAddressId, getAddressString } from '../../Address/shippingSelect';
import ShippingForm from '../../../Form/ShippingForm';
import { NanoCheckbox } from '@nanoporetech-digital/components-react';
import { useSetCustomerNewShippingAddress, useSetCustomerExistingShippingAddress, updateContextCart } from '../../../../hooks/useCart';
import SectionSubmit from '../sectionSubmit';
import AdditionalRecipients from './additionalRecipients';
import { saveSection, frontendToBackendCartAddress } from '../helpers';
import { autofill, validate, validateEmailCsv } from '../../../Form/validate';
import * as styles from '../style.module.css';

export const CODE = 'shipping_address';
export const TITLE = 'Delivery address';

export default function ShippingAddress({step, cartData}) {
    const {cart, setCart} = useCartContext();
    const [checkout, setCheckout] = useCheckoutContext();
    const isActive = checkout.activeStep===step;
    const [setNewShippingMutation] = useSetCustomerNewShippingAddress(cartData.id);
    const [setExistingShippingMutation] = useSetCustomerExistingShippingAddress(cartData.id);
    const shippingForm = useRef(null);

    // context level state for this section
    const section = checkout[CODE] ?? {
        shipping_address_selection: '',
        ont_shipping_emails: '',
        newShipping: {
            firstname: '',
            lastname: '',
            telephone: '',
            company: '',
            street: '',
            city: '',
            region: '',
            region_id: 0,
            postcode: '',
            country_code: ''
        }
    };

    // local state to avoid global re-render on small changes
    const [cache, setCache] = useState({
        showInput: !!section.ont_shipping_emails.length,
        ont_shipping_emails: section.ont_shipping_emails ?? '',
        newShipping: {...section.newShipping},
        addressBook: []
    });

    async function submit() {
        const saId = getSelectedAddressId(cache.addressBook, section.shipping_address_selection);

        if (!saId) { // new address form
            if (autofill(cache.newShipping, shippingForm.current, 'shipping_')) {
                setCache({...cache});
            }
            if (!validate(shippingForm.current, 'shipping_')) throw Error();
        }

        if (cache.ont_shipping_emails?.length) {
            if (!validateEmailCsv(cache.ont_shipping_emails)) throw Error('Invalid email address(es) for shipping notifications');
        }

        const highlight = saId ? getAddressString(cache.addressBook.find(address => address.id===saId)) : getAddressString(cache.newShipping);

        const responseData = (saId)
            ? await setExistingShippingMutation({variables: {cartId: cartData.id, customerAddressId: saId}})
            : await setNewShippingMutation({variables: {cartId: cartData.id, address: frontendToBackendCartAddress(cache.newShipping)}});

        const newCart = updateContextCart(cart, setCart, responseData);
        saveSection(CODE, checkout, setCheckout, section, {
            canChange: true,
            highlight: highlight,
            ont_shipping_emails: cache.ont_shipping_emails,
            newShipping: {...cache.newShipping},
            shippingCountryId: newCart.shipping_addresses?.length ? newCart.shipping_addresses[0].country.code : '',
        });
    }

    return(
        <div className={styles.section}>
            <SectionHeader step={step} code={CODE} title={TITLE} />
            {isActive && <SectionContent settings={{cache, setCache, section, shippingForm}} />}
            {isActive && <SectionSubmit callback={submit} disable={false} />}
        </div>
    );
}

function SectionContent({settings}) {
    const {shipping} = useAddresses();

    useEffect(() => {
        if (shipping?.length && !settings.cache.addressBook.length) {
            settings.setCache({
                ...settings.cache,
                addressBook: [...shipping]
            });
        }
    }, [shipping])

    if (shipping?.length) {
        return (
            <div className={styles.sectionContent}>
                <div className={styles.shippingAddress}>
                    <ShippingAddressBlock addresses={settings.cache.addressBook} shippingAddressId={settings.section.shipping_address_selection} />
                    <ShippingNotifications settings={settings} />
                </div>
                <AddressBook shipping={settings.cache.addressBook} settings={settings} />
            </div>
        );
    }

    return <p style={{textAlign: "center"}}><FontAwesomeIcon icon={faSpinner} spin size="lg" /></p>;
}

function ShippingNotifications({settings}) {
    const {cache, setCache} = settings;

    function toggle() {
        if (cache.showInput) {
            setCache({...cache, showInput: false, ont_shipping_emails: ''});
        } else {
            setCache({...cache, showInput: true});
        }
    }

    return (
        <div>
            <NanoCheckbox
            label="Add additional shipping notification recipients"
            checked={cache.showInput}
            onNanoChange={() => toggle()} />
            {cache.showInput && <AdditionalRecipients cache={cache} setCache={setCache} />}
        </div>
    );
}

function AddressBook({shipping, settings}) {
    const {cache, setCache, section} = settings;
    const [checkout, setCheckout] = useCheckoutContext();
    const createNewShipping = section.shipping_address_selection==='_new';
    const changeSelection = (val) => setCheckout({...checkout, [CODE]: {...section, shipping_address_selection: val}});
    const setShipping = (newShipping) => setCache({...cache, newShipping: newShipping});

    return (
        <div className={styles.addressBook}>
            <ShippingSelect addresses={shipping} selection={section.shipping_address_selection} changeSelection={changeSelection} />
            {createNewShipping && <form ref={settings.shippingForm}><ShippingForm formData={cache.newShipping} setFormData={setShipping} /></form>}
        </div>
    );
}
