import React, { useEffect, useState } from 'react';
import { useCartContext } from '../../../../contexts/CartContext';
import { useCheckoutContext } from '../../../../contexts/CheckoutContext';
import SectionHeader from '../sectionHeader';
import { useCartScheduler } from '../../../../hooks/useShippingScheduler';
import { useSaveSchedule, updateContextCart } from '../../../../hooks/useCart';
import ShippingScheduler from '../../../ShippingScheduler';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import SectionSubmit from '../sectionSubmit';
import { saveSection, pluralise } from '../helpers';
import { deleteEmptyShipments, getScheduleSaveData } from '../../../ShippingScheduler/helpers';
import Modal from '../../../Modal';
import { useDisplayEcoAlert } from '../../../../hooks/useEco';
import * as styles from '../style.module.css';

export const CODE = 'shipping_schedule';
export const TITLE = 'Delivery schedule';

export default function ShippingSchedule({step, cartData}) {
    const [checkout] = useCheckoutContext();
    const isActive = checkout.activeStep===step;

    return(
        <div className={styles.section}>
            <SectionHeader step={step} code={CODE} title={TITLE} />
            {isActive && <ActiveSection cartData={cartData} />}
        </div>
    );
}

function ActiveSection({cartData}) {
    const { cart, setCart } = useCartContext();
    const [checkout, setCheckout] = useCheckoutContext();
    const [saveScheduleMutation] = useSaveSchedule(cart.id);

    // context level state for this section
    const section = checkout[CODE] ?? {};

    // local state to avoid global re-render on small changes
    const [cache, setCache] = useState({
        schedule: section.schedule ?? null,
    });

    async function submit() {
        deleteEmptyShipments(cache.schedule);
        const responseData = await saveScheduleMutation({variables: {
            cartId: cart.id,
            rows: getScheduleSaveData(cache.schedule),
            interacted: Boolean(cache.schedule.interacted)
        }});
        updateContextCart(cart, setCart, responseData);
        saveSection(CODE, checkout, setCheckout, section, {
            canChange: true,
            highlight: pluralise(cache.schedule.shipments.length,  ' shipment'),
            schedule: cache.schedule
        });
    }

    return(
        <>
            <SectionContent settings={{cache, setCache, section}} cartData={cartData} />
            <SectionSubmit callback={submit} disable={cache.schedule?.hasError} />
        </>
    );
}

function SectionContent({settings, cartData}) {
    const { cart } = useCartContext();
    const { data, loading, error } = useCartScheduler(cart.id);
    const isEcoNeeded = useDisplayEcoAlert();
    const [hasEcoBeenShown, setHasEcoBeenShown] = useState(false);

    useEffect(() => {
        if (data?.shippingScheduleSetup && !settings.cache.schedule) {
            settings.setCache({...settings.cache, schedule: data.shippingScheduleSetup});
        }
    }, [data]);

    function setSchedule(schedule) {
        settings.setCache({...settings.cache, schedule: schedule});
    }

    if (loading) {
        return(
            <div className={styles.sectionContent}>
                <center><FontAwesomeIcon icon={faSpinner} spin size="lg" /></center>
            </div>
        );
    }

    if (error) {
        return(
            <div className={styles.sectionContent}>
                <p>Error generating shipping schedule</p>
            </div>
        );
    }

    if (!settings.cache.schedule) return null;

    return (
        <div className={styles.sectionContent}>
            <Modal
                title="Export Control Notification"
                message="Oxford Nanopore will need to apply for an export control approval before we can dispatch your items to the selected country. We will add an additional 10 days lead time when you schedule your order. A member of our support team will be in contact with you once the order has been placed."
                open={isEcoNeeded && !hasEcoBeenShown}
                setter={() => setHasEcoBeenShown(true)}
            />
            <div className="info-banner info-banner--caution">
                <div className="info-banner__content">
                    <span className="info-banner__heading">Your schedule is based on items ordered, location, and payment method</span>
                    <p className="info-banner__text">You can edit this schedule below by adding or removing shipments or changing the date of delivery. Please note that restrictions on scheduling apply depending on the products ordered and/or shipment location subject to our Terms and Conditions. <strong>Please note, amendments to order dates will not be accepted 14 days prior to the requested ship day.</strong></p>
                </div>
            </div>
            <ShippingScheduler
                schedule={settings.cache.schedule}
                setSchedule={setSchedule}
                currency={cart.subtotal.currency}
                customerCart={cartData}
            />
        </div>
    );
}
