import React, { useEffect } from 'react';
import useBillingInfo from './useBillingInfo';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import Preview from '../Preview';
import BillingInfo from '../BillingInfo';
import { backendToFrontendAddress } from '../helpers';

export default function BillingRouter({cartData, sectionCache}) {
    const { data, loading, error } = useBillingInfo();

    useEffect(() => {
        if (data?.getBillingInfo && !sectionCache.data.billing_info) {
            sectionCache.set({
                billing_info: {
                    ...data.getBillingInfo,
                    billing: backendToFrontendAddress(data.getBillingInfo.billing)
                }
            });
        }
    }, [data]);

    if (sectionCache.data.billing_info) {
        return <BillingContent billingInfo={sectionCache.data.billing_info} cartId={cartData.id} sectionCache={sectionCache} />;
    }

    if (error) {
        return <p>{error?.graphQLErrors[0]?.debugMessage}</p>;
    };

    return <p style={{textAlign: "center"}}><FontAwesomeIcon icon={faSpinner} spin size="lg" /></p>;
}

function BillingContent({billingInfo, cartId, sectionCache}) {
    switch (billingInfo.status) {
        case 'complete':
		case 'myaccount':
		case 'invacc':
			return <Preview cartId={cartId} sectionCache={sectionCache} />;
        case 'form': return <BillingInfo sectionCache={sectionCache} />;
        case 'redirect': return <RedirectToMyAccount billingInfo={billingInfo} />;
        case 'nocustomer': return <LoginRequired />;
    }

    return <p>Invalid registration status. Please contact Customer Support.</p>;
}

function RedirectToMyAccount({billingInfo}) {
    return <p>Billing details for your account <code>{billingInfo.c_number}</code> are incomplete. Please visit <a href="">My Account</a> to set these up before returning to Checkout.</p>;
}

function LoginRequired() {
    return <p>LoginRequired</p>;
}
