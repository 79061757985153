import React from 'react';
import { OptionalStatus } from './sectionStatus';
import UiMarkdown from "../../../Ui/UiMarkdown";
import * as styles from './style.module.css';
import { NanoGrid, NanoSelect, NanoOption } from '@nanoporetech-digital/components-react';
import Price from "../../../Ui/Price";

// it would be good to get name and short_description from the category
const groups = [
    { // used in MinION bundles - configure in related products
        parent: "SUPP1D",
        name: "Rapid Start Day Training",
        short_description: "A comprehensive online two-day training providing practical experience with an introduction to nanopore sequencing workflows and analysis.",
        options:  [
            {value: "", label: "-- None selected --"},
            {value: "SUPP1D", label: "DNA"},
        ],
    },
    { // used in PromethION bundles - configure in related products
        parent: "SUPP008",
        name: "PromethION Advanced Nanopore Training",
        short_description: "A comprehensive on-site, two and half-day training providing practical experience with advanced nanopore sequencing workflows and analysis.",
        options:  [
            {value: "", label: "-- None selected --"},
            {value: "SUPP008", label: "DNA/RNA"},
        ],
    },
    { // used in GridION bundles - configure in related products
        parent: "SUPP001",
        name: "GridION Advanced Nanopore Training",
        short_description: "A comprehensive on-site, two and half-day training providing practical experience with advanced nanopore sequencing workflows and analysis.",
        options:  [
            {value: "", label: "-- None selected --"},
            {value: "SUPP001", label: "DNA/RNA"},
        ],
    }
];

export default function Training(props) {
    const productGroups = groups.map(group => {
        const parent = props.product.related_products.find(rp => rp.sku===group.parent);
        if (!parent) return {};

        const item = {
            parent_sku: group.parent,
            short_description: group.short_description,
            name: group.name,
            ont_image_url_1: parent.ont_image_url_1,
            prices_from: (parent.ont_custom_price ??  parent.price_range.maximum_price.final_price),
            options: group.options,
            value: (props.section.cache[group.parent] ?? '')
        }
        return item;
    });

    const updateSection = (parentSku, selectValue) => {
        const cache = {...props.section.cache};
        cache[parentSku] = selectValue;
        if (!selectValue) delete cache[parentSku];

        const items = [];
        const additional = {};
        productGroups.forEach(group => {
            const sku = group.parent_sku;
            if (sku && cache[sku]) {
                const value = cache[sku];  // this is the value of the selected option for this group
                const label = group.options.find(option => option.value===value)?.label;
                const additionalProduct = props.product.related_products.find(rp => rp.sku===value);
                items.push({name: `${group.name} - ${label}`, qty: 1, surcharge: group.prices_from.value });
                additional[value] = {product: additionalProduct, qty: 1};
            }
        });

        props.updateSection({...props.section, items, cache, additional});
    };

    return (
        <NanoGrid l-cols="10"
            m-cols="10"
            s-cols="10"
            xl-cols="10"
            xxl-cols="10" className={styles.sectionStatus}>
             <div grid-states="xl-col-span-10 l-col-span-10 m-col-span-10 s-col-span-10" className={styles.instructions}>
                <OptionalStatus noun="Optional training packages" goNextStep={props.goNextStep} />
            </div>
            <div grid-states="xl-col-span-6 l-col-span-6 m-col-span-10 s-col-span-10">
                <div className={styles.sectionContent}>
                    {productGroups.map((item,i) => <Item item={item} key={i} updateSection={updateSection} />)}
                </div>
            </div>
            {props.summary}
       </NanoGrid>
    );
}

function Item({item, updateSection}) {
    if (!Object.keys(item).length) return null;

    const description = item.short_description.replace(/<[^>]+>/g, '');
    const { currency, value } = item.prices_from;

    return (
        <div className={styles.item}>
            <h2 className={styles.title}>{item.name}</h2>
            <div className={styles.details}>
                <div className={styles.image}><img src={item.ont_image_url_1} /></div>
                <div className={styles.description}>
                    <div><UiMarkdown source={description} /></div>
                    <div className={styles.itemCta}>
                        <div className={styles.price}>Prices from <strong><Price currencyCode={currency} value={value} classes={{decimal: styles.hide, fraction: styles.hide}} /></strong></div>
                        <div className={styles.ctaSelect}>
                            <NanoSelect
                                placeholder="-- None selected --"
                                clear-select="false"
                                onNanoChange={e =>updateSection(item.parent_sku, e.target.value)}
                                value={item.value}
                            >
                                {item.options.map(it => <NanoOption key={it.value} value={it.value} label={it.label} />)}
                            </NanoSelect>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
