import React, { useEffect } from "react";
import useTaxSystem from "./useTaxSystem";
import useTaxInfo from "./useTaxInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { updateCachedTaxInfo } from "../helpers";
import TaxInfo from "../TaxInfo";
import Preview from "../Preview";

export default function TaxRouter({cartData, sectionCache}) {
    const { data: taxSystemResponse, error: taxSystemError } = useTaxSystem(cartData.id);
    const { data: taxInfoResponse, error: taxInfoError } = useTaxInfo();

    const taxSystem = taxSystemResponse?.getTaxSystem;
    const taxInfo = taxInfoResponse?.getTaxInfo;
    const taxSystemInfoAvailable = taxSystem && taxInfo;

    useEffect(() => {
        if (taxSystemInfoAvailable && !sectionCache.data.tax_info) {
            if (taxInfo.has_account || taxSystem.tax_system_code === 'none') {
                sectionCache.set({
                    ...sectionCache.data,
                    mode: 'preview-only',
                    tax_info: taxInfo
                })
            } else {
                updateCachedTaxInfo(sectionCache, 'tax_status', taxSystem.default_status_code);
            }
        }
    }, [taxSystemInfoAvailable]);

    if (taxInfoError) {
        return <p>{taxInfoError?.graphQLErrors[0]?.debugMessage}. Please contact customer services.</p>
    }

    if (taxSystemError) {
        return <p>{taxSystemError?.graphQLErrors[0]?.debugMessage}. Please contact customer services.</p>
    }

    if (taxSystemInfoAvailable && sectionCache?.data?.mode) {
        return (sectionCache.data.mode.startsWith('preview'))
            ? <Preview taxSystem={taxSystem} cartId={cartData.id} sectionCache={sectionCache} />
            : <TaxInfo taxSystem={taxSystem} sectionCache={sectionCache} />;
    }

    return <p style={{textAlign: "center"}}><FontAwesomeIcon icon={faSpinner} spin size="lg" /></p>;
}
